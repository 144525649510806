import React from 'react';
import cx from 'classnames';

import IconTypes from '../../../../design-tokens/generated/Icons';
import type {IconSource} from '../../../../design-tokens/generated/Icons';
import type {LogoSizeModifier} from '../../../types/sizes';
import type {ColorScheme} from '../../../types/variants';

import styles from './Icon.module.scss';

export const Icon: React.FC<
    React.ComponentProps<'svg'> & {
        size?: LogoSizeModifier;
        colorScheme?: ColorScheme;
        source: IconSource;
    }
> = ({source, className, size = 'small', colorScheme, ...props}) => {
    const IconComponent =
        size === 'mini' || size === 'compact'
            ? source.size16
            : size === 'small'
            ? source.size24
            : source.size32;

    return (
        <IconComponent
            className={cx(
                colorScheme && styles[colorScheme],
                styles[size],
                className,
            )}
            {...props}
        />
    );
};

export {IconTypes};
