import React from 'react';

import {Button, Group, Icon, IconTypes} from '@pexip/components';

import styles from './Toolbar.module.scss';

const stopMouseEventPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const Toolbar: React.FC<{
    audioMuted: boolean;
    canShare: boolean;
    isPresenting: boolean;
    leave: () => void;
    toggleAudioMutedState: () => void;
    togglePresenting: () => void;
    toggleVideoMutedState: () => void;
    videoMuted: boolean;
}> = ({
    audioMuted,
    canShare,
    isPresenting,
    leave,
    toggleAudioMutedState,
    togglePresenting,
    toggleVideoMutedState,
    videoMuted,
}) => {
    return (
        <Group
            spacing="small"
            justifyContent="center"
            className={styles.toolbar}
        >
            <Button
                modifier="square"
                size="medium"
                onPointerDownCapture={stopMouseEventPropagation}
                onClick={toggleAudioMutedState}
                variant={audioMuted ? 'danger' : 'translucent'}
            >
                {audioMuted ? (
                    <Icon source={IconTypes.IconMicrophoneOff} />
                ) : (
                    <Icon source={IconTypes.IconMicrophoneOn} />
                )}
            </Button>
            <Button
                modifier="square"
                size="medium"
                onPointerDownCapture={stopMouseEventPropagation}
                onClick={toggleVideoMutedState}
                variant={videoMuted ? 'danger' : 'translucent'}
            >
                {videoMuted ? (
                    <Icon source={IconTypes.IconVideoOff} />
                ) : (
                    <Icon source={IconTypes.IconVideoOn} />
                )}
            </Button>
            {canShare && (
                <Button
                    modifier="square"
                    size="medium"
                    onPointerDownCapture={stopMouseEventPropagation}
                    onClick={togglePresenting}
                    variant={isPresenting ? 'primary' : 'translucent'}
                >
                    <Icon source={IconTypes.IconPresentationOn} />
                </Button>
            )}

            <Button
                modifier="square"
                size="medium"
                onPointerDownCapture={stopMouseEventPropagation}
                onClick={leave}
                variant="danger"
            >
                <Icon source={IconTypes.IconLeave} />
            </Button>
        </Group>
    );
};
