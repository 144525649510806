import React from 'react';

import {UserMenuDropContent} from '@pexip/components';

import styles from './SettingsMenu.module.scss';

export const SettingsMenu: React.FC<
    React.ComponentProps<typeof UserMenuDropContent>
> = props => (
    <UserMenuDropContent
        position="bottomRight"
        modifier="square"
        variant="translucent"
        contentClassName={styles.content}
        className="mr-2"
        {...props}
    />
);
