import React, {useRef} from 'react';

import {Draggable, Video, VideoWrapper} from '@pexip/components';

import styles from './Selfview.module.scss';

export const Selfview: React.FC<{stream?: MediaStream}> = ({stream}) => {
    const floatRef = useRef(document.body as HTMLDivElement);

    return (
        <Draggable
            floatRoot={floatRef}
            className={styles.draggable}
            style={{top: 20, right: 20}}
        >
            <VideoWrapper background="secondary">
                <Video srcObject={stream} autoPlay muted isMirrored />
            </VideoWrapper>
        </Draggable>
    );
};
