import type {Signal} from '@pexip/signal';

/**
 *  Status Codes for Websocket close
 *
 *  @see {@link https://datatracker.ietf.org/doc/html/rfc6455#section-7.4.1|RFC 6455}
 */
export enum SocketCloseEventCode {
    NormalClosure = 1000,
    GoingAway = 1001,
    AbnormalClosure = 1006,
    Reconnect = 4000,
    Finalize = 4999,
}

export interface SocketSignals<T extends Message> {
    onConnecting: Signal<undefined>;
    onConnected: Signal<undefined>;
    onReconnecting: Signal<undefined>;
    onReconnected: Signal<undefined>;
    onDisconnected: Signal<CloseEvent>;
    onError: Signal<undefined>;
    onMessage: Signal<T>;
}

export interface Message {
    type: string;
    id?: string;
    ref?: string;
}
