import React, {
    createContext,
    useEffect,
    useState,
    useSyncExternalStore,
} from 'react';

import type {Media, MediaController} from '@pexip/media';
import {createMediaSignals, createMedia} from '@pexip/media';
import {isEmpty} from '@pexip/utils';
import {
    createPreviewAudioInputHook,
    createPreviewAudioOutputHook,
    createPreviewControllerHook,
    createPreviewVideoInputHook,
} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {toMediaDeviceInfo} from '@pexip/media-control';

import {config} from '../config';
import type {GetState, Subscribe} from '../types';
import {DEFAULT_HEIGHT, DEFAULT_WIDTH} from '../constants';

export const mediaSignals = createMediaSignals([
    'onStatusChanged',
    'onDevicesChanged',
    'onStreamTrackEnabled',
    'onStreamTrackEnded',
    'onStreamTrackMuted',
    'onStreamTrackUnmuted',
]);

export const MediaContext = createContext<MediaController | undefined>(
    undefined,
);

export const mediaController = createMedia({
    getDefaultConstraints: () => ({
        audio: {
            ...(isEmpty(config.get('audioInput'))
                ? {}
                : {device: config.get('audioInput')}),
        },
        video: {
            ...(isEmpty(config.get('videoInput'))
                ? {}
                : {device: config.get('videoInput')}),
            width: DEFAULT_WIDTH,
            height: DEFAULT_HEIGHT,
        },
    }),
    getMuteState: () => ({
        audio: config.get('audioInputMuted'),
        video: config.get('videoInputMuted'),
    }),
    mediaProcessors: [],
    signals: mediaSignals,
});

export const usePreviewController = createPreviewControllerHook(() => ({
    getCurrentDevices: () => mediaController.devices,
    getCurrentMedia: () => mediaController.media,
    updateMainStream: mediaController.getUserMediaAsync,
    mediaSignal: mediaSignals.onMediaChanged,
    processors: [],
}));

export const usePreviewAudioOutput = createPreviewAudioOutputHook(
    (value: MediaDeviceInfoLike) => config.set({key: 'audioOutput', value}),
);

export const usePreviewAudioInput = createPreviewAudioInputHook({
    get: () => config.get('audioInput'),
    getExpected: () => mediaController.media.expectedAudioInput,
    set: (value?: MediaDeviceInfoLike) => {
        if (!value) {
            return;
        }
        config.set({
            key: 'audioInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        });
    },
});

export const usePreviewVideoInput = createPreviewVideoInputHook({
    get: () => config.get('videoInput'),
    getExpected: () => mediaController.media.expectedVideoInput,
    set: (value?: MediaDeviceInfoLike) => {
        if (!value) {
            return;
        }
        config.set({
            key: 'videoInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        });
    },
});

config.subscribe('audioInputMuted', isMuted =>
    mediaController.media.muteAudio(isMuted),
);
config.subscribe('videoInputMuted', isMuted =>
    mediaController.media.muteVideo(isMuted),
);

export const MediaProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => (
    <MediaContext.Provider value={mediaController}>
        {children}
    </MediaContext.Provider>
);

export const useLocalMedia = (
    getMedia: () => Media,
    subscribe: Subscribe = mediaSignals.onMediaChanged.add,
) => useSyncExternalStore(subscribe, getMedia);

export const useDevices = (
    getDevices: GetState<Media, 'devices'>,
    subscribe: Subscribe = mediaSignals.onDevicesChanged.add,
) => useSyncExternalStore(subscribe, getDevices);

export const useStreamStatus = (
    getStatus: GetState<Media, 'status'>,
    subscribe: Subscribe = mediaSignals.onStatusChanged.add,
) => useSyncExternalStore(subscribe, getStatus);

export const muteAudio = (mute: boolean, persist?: boolean) =>
    config.set({key: 'audioInputMuted', value: mute, persist});
export const muteVideo = (mute: boolean, persist?: boolean) =>
    config.set({key: 'videoInputMuted', value: mute, persist});

export const toggleAudioMuted = (persist?: boolean) => {
    if (mediaController.media.audioMuted === undefined) {
        return;
    }
    muteAudio(!mediaController.media.audioMuted, persist);
};
export const toggleVideoMuted = (persist?: boolean) => {
    if (mediaController.media.videoMuted === undefined) {
        return;
    }
    muteVideo(!mediaController.media.videoMuted, persist);
};

export const useAudioMuteState = (media: Media) => {
    const [audioMuted, muteAudio] = useState(media.audioMuted);
    const [audioUnavailable, setAudioUnavailable] = useState(
        () => mediaController.media.audioMuted === undefined,
    );

    useEffect(() => {
        if (media.audioMuted === undefined) {
            setAudioUnavailable(true);
        } else {
            setAudioUnavailable(false);
            muteAudio(media.audioMuted);
        }
    }, [media.audioMuted]);

    useEffect(
        () =>
            mediaSignals.onStreamTrackEnabled.add(track => {
                if (track.kind === 'audio') {
                    muteAudio(mediaController.media.audioMuted);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackMuted.add(track => {
                if (track.kind === 'audio') {
                    muteAudio(mediaController.media.audioMuted);
                    setAudioUnavailable(true);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackUnmuted.add(track => {
                if (track.kind === 'audio') {
                    muteAudio(mediaController.media.audioMuted);
                    if (track.readyState !== 'ended') {
                        setAudioUnavailable(false);
                    }
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackEnded.add(track => {
                if (track.kind === 'audio') {
                    setAudioUnavailable(true);
                }
            }),
        [],
    );

    return {
        audioMuted: audioUnavailable || audioMuted !== false,
        audioUnavailable,
    };
};

export const useVideoMuteState = (media: Media) => {
    const [videoMuted, muteVideo] = useState(media.videoMuted);
    const [videoUnavailable, setVideoUnavailable] = useState(
        () => mediaController.media.videoMuted === undefined,
    );

    useEffect(() => {
        if (media.videoMuted === undefined) {
            setVideoUnavailable(true);
        } else {
            setVideoUnavailable(false);
            muteVideo(media.videoMuted);
        }
    }, [media.videoMuted]);

    useEffect(
        () =>
            mediaSignals.onStreamTrackEnabled.add(track => {
                if (track.kind === 'video') {
                    muteVideo(mediaController.media.videoMuted);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackMuted.add(track => {
                if (track.kind === 'video') {
                    muteVideo(mediaController.media.videoMuted);
                    setVideoUnavailable(true);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackUnmuted.add(track => {
                if (track.kind === 'video') {
                    muteVideo(mediaController.media.videoMuted);
                    if (track.readyState !== 'ended') {
                        setVideoUnavailable(false);
                    }
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackEnded.add(track => {
                if (track.kind === 'video') {
                    setVideoUnavailable(true);
                }
            }),
        [],
    );

    return {
        videoMuted: videoUnavailable || videoMuted !== false,
        videoUnavailable,
    };
};
