import '@pexip/components/src/fonts.css';
import '@pexip/components/dist/style.css';
import '@pexip/media-components/dist/style.css';
import './styles/main.scss';

import React from 'react';
import {createRoot} from 'react-dom/client';

import './i18n';
import {App} from './App';
import {MeeProvider} from './contexts/mee.context';
import {MediaProvider} from './contexts/media.context';
import {ConfigProvider} from './contexts/config.context';

// getElementById can be potentially null, but not worth handling, too broken if it is
const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
    <ConfigProvider>
        <MeeProvider>
            <MediaProvider>
                <App />
            </MediaProvider>
        </MeeProvider>
    </ConfigProvider>,
);
