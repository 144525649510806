import React from 'react';

import {Bar, CoreHeader} from '@pexip/components';
import {MeetingScrim} from '@pexip/media-components';

export const Header: React.FC<
    React.ComponentProps<typeof CoreHeader>
> = props => (
    <MeetingScrim>
        <Bar position="absoluteTop">
            <CoreHeader hasBottomBorder={false} enhancerFixedWidth {...props} />
        </Bar>
    </MeetingScrim>
);
