import React, {useCallback, useState} from 'react';

import type {RouteMatch} from '@pexip/router';
import {isDefined} from '@pexip/utils';

import {InMeeting} from '../viewModels/InMeeting.viewModel';
import {Preflight} from '../viewModels/Preflight.viewModel';
import {PostMeeting} from '../viewModels/PostMeeting.viewModel';
import type {Step} from '../types';

export const Meeting: React.FC<{match: RouteMatch}> = ({
    match: {
        params: {id},
    },
}) => {
    const [step, setStep] = useState<Step>('preflight');

    const call = useCallback(() => {
        setStep('in-meeting');
    }, []);

    const leave = useCallback(() => {
        setStep('post-meeting');
    }, []);

    switch (step) {
        case 'in-meeting':
            return <InMeeting meetingId={isDefined(id)} leave={leave} />;

        case 'post-meeting':
            return <PostMeeting />;

        default:
            return <Preflight call={call} />;
    }
};
