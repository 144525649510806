import React from 'react';

import {MediaContext, useLocalMedia} from '../contexts/media.context';
import {useAssertedContext} from '../hooks/useAssertedContext';
import {Selfview} from '../views/Selfview/Selfview.view';

export const LocalStream: React.FC = () => {
    const m = useAssertedContext(MediaContext);
    const media = useLocalMedia(() => m.media);

    return <Selfview stream={media.stream} />;
};
