import React from 'react';

import {Settings} from '../viewModels/Settings/Settings.viewModel';
import {Header} from '../viewModels/Header.viewModel';
import type {Step} from '../types';

export function withHeader<P extends object>(
    Component: React.ComponentType<P>,
    step: Step,
) {
    return function WrappedWithColorScheme(props: P) {
        return (
            <>
                <Header enhancerEnd={<Settings step={step} />}></Header>
                <Component {...props} />
            </>
        );
    };
}
