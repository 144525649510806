import React, {useCallback, useEffect, useState} from 'react';

import {useHotKey} from '@pexip/hooks';

import {
    MediaContext,
    toggleVideoMuted,
    useAudioMuteState,
    useLocalMedia,
    useVideoMuteState,
    toggleAudioMuted,
} from '../contexts/media.context';
import {useAssertedContext} from '../hooks/useAssertedContext';
import {MeeContext, onPresentation} from '../contexts/mee.context';
import {Toolbar} from '../views/Toolbar/Toolbar.view';

const canShare =
    navigator?.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices;

export const Footer: React.FC<{
    leave: () => void;
}> = ({leave}) => {
    const mee = useAssertedContext(MeeContext);
    const m = useAssertedContext(MediaContext);

    const [isPresenting, setPresenting] = useState(false);

    const media = useLocalMedia(() => m.media);

    const {audioMuted} = useAudioMuteState(media);
    const {videoMuted} = useVideoMuteState(media);

    const toggleAudioMutedState = useCallback(() => {
        toggleAudioMuted();
    }, []);

    const toggleVideoMutedState = useCallback(() => {
        toggleVideoMuted();
    }, []);

    const togglePresenting = useCallback(() => {
        isPresenting ? void mee.stopPresenting() : void mee.present();
    }, [isPresenting, mee]);

    useHotKey('m', toggleAudioMutedState);
    useHotKey('c', toggleVideoMutedState);

    useEffect(() => onPresentation.add(stream => setPresenting(!!stream)), []);

    return (
        <Toolbar
            audioMuted={audioMuted}
            videoMuted={videoMuted}
            isPresenting={isPresenting}
            toggleAudioMutedState={toggleAudioMutedState}
            toggleVideoMutedState={toggleVideoMutedState}
            togglePresenting={togglePresenting}
            leave={leave}
            canShare={canShare}
        />
    );
};
