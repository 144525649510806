import React, {Children} from 'react';
import cx from 'classnames';

import type {Layout} from '../../types';

import styles from './VideoGrid.module.scss';

export const VideoGrid: React.FC<
    React.PropsWithChildren<{layout?: Layout}>
> = ({layout = 'ac', ...props}) => (
    <div className={styles.wrapper}>
        <div
            {...props}
            className={cx(
                styles.grid,
                styles[layout],
                styles[
                    `grid${
                        Children.toArray(props.children).filter(Boolean)
                            .length as
                            | 1
                            | 2
                            | 3
                            | 4
                            | 5
                            | 6
                            | 7
                            | 8
                            | 9
                            | 10
                            | 11
                            | 12
                            | 13
                            | 14
                            | 15
                            | 16
                            | 17
                            | 18
                    }`
                ],
            )}
        />
    </div>
);
