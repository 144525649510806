import {ConfigManager} from '@pexip/config-manager';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {StreamQuality} from '@pexip/media-components';

import {API_ADDRESS, NAMESPACE} from './constants';

export const DEFAULT_CONFIG = {
    apiAddress: API_ADDRESS,
    audioInput: {} as MediaDeviceInfoLike | undefined,
    audioInputMuted: false,
    audioOutput: {} as MediaDeviceInfoLike | undefined,
    nameLabels: false,
    streamQuality: StreamQuality.Medium,
    videoInput: {} as MediaDeviceInfoLike | undefined,
    videoInputMuted: false,
};

export type Config = typeof DEFAULT_CONFIG;

export const config = new ConfigManager(DEFAULT_CONFIG, NAMESPACE);
config.load({});
