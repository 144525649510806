import React from 'react';

import {
    FullscreenLayout,
    VideoWrapper,
    FontVariant,
    Text,
} from '@pexip/components';

export const FullscreenText: React.FC<React.PropsWithChildren> = props => (
    <FullscreenLayout>
        <VideoWrapper>
            <Text
                fontVariant={FontVariant.H2}
                whiteSpace="preLine"
                {...props}
            />
        </VideoWrapper>
    </FullscreenLayout>
);
