import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

type LocaleCode = 'en';

const importBackend = {
    type: 'backend' as const,
    read<Namespace extends string>(
        language: LocaleCode,
        namespace: Namespace,
        callback: (errorValue: unknown, translations: unknown) => void,
    ) {
        import(`./locales/${language}/${namespace}.json`)
            .then(resources => callback(null, resources))
            .catch(err => callback(err, null));
    },
};

void i18n
    .use(importBackend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        supportedLngs: ['en'],
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });
