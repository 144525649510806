import React from 'react';

import {
    FontVariant,
    Icon,
    IconTypes,
    Text,
    Video,
    VideoWrapper,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './VideoGridItem.module.scss';

export const VideoGridItem: React.FC<
    React.ComponentPropsWithRef<typeof Video> & {
        id?: string;
        showName?: boolean;
        isPresentation?: boolean;
    }
> = ({id, showName = true, isPresentation = false, ...props}) => (
    <div className={styles.wrapper}>
        <VideoWrapper background="secondary" className={styles.videoWrapper}>
            {showName && (
                <div className={styles.overlay}>
                    {isPresentation && (
                        <Icon
                            source={IconTypes.IconPresentationOn}
                            className={styles.icon}
                        />
                    )}
                    <Text
                        colorScheme="light"
                        fontVariant={FontVariant.BodyBold}
                        isTruncated
                    >
                        {id}
                    </Text>
                </div>
            )}
            <Video
                autoPlay
                muted
                draggable
                data-testid={TestId.VideoRemote}
                className={styles.video}
                {...props}
            />
        </VideoWrapper>
    </div>
);
