import {v4 as uuid} from 'uuid';

import {Backoff} from '@pexip/utils';

import {BACKOFF_BASE_SETTINGS, MAX_RECONNECT_ATTEMPTS} from './constants';
import {logger} from './logger';
import type {Message, SocketSignals} from './types';
import {SocketCloseEventCode} from './types';
import {
    isClosed,
    isConnecting,
    isOpen,
    isWebSocketClosedWithError,
} from './utils';

export const createSocketManager = <T extends Message>(
    signals: SocketSignals<T>,
    maxReconnectAttempts = MAX_RECONNECT_ATTEMPTS,
) => {
    let ws: WebSocket | undefined;
    let reconnectTimmer: number;
    let reconnectAttempt = 0;

    const _connect = (
        url: string,
        protocols?: string,
        backoff = new Backoff(BACKOFF_BASE_SETTINGS),
    ) => {
        clearTimeout(reconnectTimmer);
        if (isOpen(ws?.readyState)) {
            logger.warn('Connection already established.');
            return;
        }
        ws = new window.WebSocket(url, protocols);
        window.pexDebug = {...window.pexDebug, ws};

        if (reconnectAttempt === 0) {
            signals.onConnecting.emit();
        } else {
            signals.onReconnecting.emit();
        }

        ws.onopen = event => {
            logger.info({event}, 'Connection opened');
            if (reconnectAttempt === 0) {
                signals.onConnected.emit();
            } else {
                signals.onReconnected.emit();
                reconnectAttempt = 0;
                backoff.reset();
            }
        };

        ws.onmessage = (msg: MessageEvent<string>) => {
            try {
                const event = JSON.parse(msg.data) as T;
                if ('token' in event && typeof event.token === 'string') {
                    logger.redact(event.token);
                }
                logger[event.type === 'error' ? 'error' : 'debug'](
                    {event},
                    `Message received with type ${event.type}`,
                );
                signals.onMessage.emit(event);
            } catch (error) {
                logger.error({error}, 'Parsing message failed');
            }
        };

        ws.onerror = error => {
            logger.error({error}, 'Connection error');
            signals.onError.emit();
        };

        ws.onclose = event => {
            if (
                isWebSocketClosedWithError(event.code) &&
                reconnectAttempt < maxReconnectAttempts
            ) {
                logger.error(
                    {event},
                    'Connection closed with error, reconnecting',
                );
                reconnectAttempt += 1;
                reconnectTimmer = window.setTimeout(() => {
                    _connect(url, protocols, backoff);
                }, backoff.duration());
            } else {
                logger.error({event}, 'Connection closed');
                signals.onDisconnected.emit(event);
            }
        };
    };

    const connect = async ({
        abortController,
        url,
    }: {
        url: string;
        abortController?: AbortController;
    }) => {
        return new Promise((resolve, reject) => {
            if (abortController?.signal?.aborted) {
                return reject(abortController?.signal.reason);
            }

            const handleAbort = () => {
                if (isConnecting(ws?.readyState)) {
                    disconnect(SocketCloseEventCode.NormalClosure);
                }
                reject(abortController?.signal.reason);
            };

            abortController?.signal.addEventListener('abort', handleAbort, {
                once: true,
            });

            const withAbortController =
                (fn: (value: unknown) => void) => (args: unknown) => {
                    abortController?.signal.removeEventListener(
                        'abort',
                        handleAbort,
                    );
                    fn(args);
                };

            signals.onConnected.addOnce(withAbortController(resolve));
            signals.onError.addOnce(withAbortController(reject));
            _connect(url);
        });
    };

    const disconnect = (code?: SocketCloseEventCode, reason?: string) => {
        ws?.close(code, reason);
    };

    const send = (data: T) => {
        if (!data.id) {
            data.id = uuid();
        }
        if ('token' in data && typeof data.token === 'string') {
            logger.redact(data.token);
        }

        if (isClosed(ws?.readyState)) {
            logger.warn({data}, 'Websocket closed. Ignoring sending data');
            return;
        }

        ws?.send(JSON.stringify(data));
        logger.debug({data}, `Message sent with type ${data.type}`);

        return data.id;
    };

    return {
        connect,
        disconnect,
        send,
        get readyState() {
            return ws?.readyState;
        },
        get bufferedAmount() {
            return ws?.bufferedAmount;
        },
    };
};
